<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6" class="page-header">
        <h1 class="page-title">Campaigns</h1>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="6" md="6" sm="6">
        <!-- <v-btn color="primary" @click="$router.push({ name: 'website.discount.code.create' })"
          >+ Create new campaign</v-btn
        > -->
      </v-col>
      <v-col class="px-3 py-3">
        <Notification v-if="error" :messages="error" type="error" />

        <v-row class="elevation-1 elevation-form page__table-container">
          <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />

          <v-col cols="12">
            <Resource
              :document="document"
              :filter-fields="null"
              :filter-data="filterData"
              :filter-callback="setFilterData"
              :searchable="searchableField"
              :columns="columns"
              :update-router-callback="getUpdateRouterDataCampaign"
              :callback-action="getAction"
              :actionItems="items"
              :routeName="updateRouteName"
            />
            <!-- <pagination :page.sync="page"></pagination> -->
            <v-col cols="12" class="d-flex justify-space-between">
              <limit-page :callback="setLimit" />
              <pagination
                :visible="5"
                v-if="!$parent.isLoading && document"
                :length="lengthPage"
                :page.sync="page"
              ></pagination>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <delete-action :content="''" title="Delete Campaign" />
  </div>
</template>

<script>
import { printHubApi } from '@/apis/printHub';
import datetime from '@/helpers/datetime';
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { log } from 'handlebars';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      showDialogDelete: false,
      ids: [],
      error: null,
      isLoading: false,
      datetime,
      items: [{ title: 'Delete discounts', action: 'delete' }],
      tabs: [{ name: 'All', key: '' }],
      resourceType: 'campaign',
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },
        Campaign: {
          field: 'title',
          kind: 'title-campaign',
        },
        Status: {
          kind: 'status-campaign',
          field: `status-campaign`,
        },
        Actions: {
          kind: 'action',
          listAction: [
            // {
            //   name: 'duplicate',
            // },
            {
              name: 'bulk-duplicate',
            },
            // {
            //   name: 'relaunch',
            // },
            {
              name: 'delete',
            },
          ],
        },
      },
      updateRouteName: 'website.apps.print-hub.campaigns.edit',
      searchableField: 'title_search',
      sortable: [],
    };
  },
  created() {},
  methods: {
    async submitDelete() {
      try {
        let params = {
          ids: this.ids,
        };
        let data = await printHubApi.deleteCampaign(this.ids);

        await this.getResources();
      } catch (error) {}
      this.showDialogDelete = false;
    },
    getUpdateRouterDataCampaign(id) {
      let item = this.document.find(it => it._id === id);
      if (item.status === 2) return;
      if (item.product) {
        this.$router.push({ name: 'website.products.update', params: { id: item.product } });
      }
      if ((item.status === 1 || item.status === 0) && !item.product) {
        this.$router.push({ name: 'website.apps.print-hub.campaigns.edit', params: { id: id } });
      }
      // else {
      //   this.$router.push({ name: 'website.apps.print-hub.campaigns.edit', params: { id: id } });
      // }
    },
    getAction(action, ids) {
      if (action.action == 'delete') {
        this.showDialogDelete = true;
        this.ids = ids;
        // this.ids = ids;
        // let index = this.list
        console.log('id', ids);
      }
      if (action.action == 'bulk-duplicate') {
        this.$router.push({
          name: 'website.apps.print-hub.campaigns.bulk-duplicate',
          params: { id: ids },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
